import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import OurPerspectives from "../components/Solutions/OurPerspectives/OurPerspectives";
import StraumannCaseStudy from "../components/Platforms/StraumannCaseStudy/StraumannCaseStudy";
import AdobeExperienceManagerVideo from "../components/Platforms/AdobeExperienceManagerVideo/AdobeExperienceManagerVideo";
import OurProjects from "../components/Solutions/OurProjects/OurProjects";
import AdobeExperienceManagerTools from "../components/Platforms/AdobeExperienceManagerTools/AdobeExperienceManagerTools";
import AdobeExperienceManagerSiteServices from "../components/Platforms/AdobeExperienceManagerSiteServices/AdobeExperienceManagerSiteServices";
import AdobeExperienceManagerBanner from "../components/Platforms/AdobeExperienceManagerBanner/AdobeExperienceManagerBanner";
import { Helmet } from "react-helmet";
function AdobeExperienceManager() {
	const scrollTop = () => {
		window.scroll(0, 1);
	};
	return (
		<Layout><Helmet>
		<meta charSet="utf-8" />
		   <title>
	   Hashout Technologies - Adobe Experience Manager

		   </title>
		   <meta
			 name="description"
			 content="Personalized digital experience across channels."
		   ></meta></Helmet>
			<SolutionsHelmet />
			<AdobeExperienceManagerBanner />
			<StraumannCaseStudy />
			<AdobeExperienceManagerSiteServices />
			<AdobeExperienceManagerVideo />
			<OurProjects />
			<AdobeExperienceManagerTools />
			<OurPerspectives />
			<LearnAboutUs page="adobe-experience-manager"/>
			<a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
				<img
					className="go-to-top__img"
					src="../../solutions/go-to-top.png"
					alt="Go to top"
				/>
			</a>
		</Layout>
	);
}
export default AdobeExperienceManager;
