import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function AdobeExperienceManagerBanner() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading container__heading--platform">
              Adobe Experience Manager{" "}
            </h1>
            <img
              className="container__logo"
              src="../../solutions/platforms/solution-partner.png"
              alt="Adobe Experience Manager"
            />
            <h2 className="container__sub-heading container__sub-heading--platform">
              <span className="--red-color">Personalized</span> digital
              experience across channels
            </h2>
            <h6 className="container__content">
              Our certified AEM Experts provide multi-solution development
              support to customers in Technology, Education, FMCG, and
              Healthcare.
            </h6>
            <a
              className="container__link"
              href="/blog/Adobe-Experience-Manager-6.5-vs-WordPress-Feature-Comparison"
              target="_blank"
            >
              Read WordPress - AEM Sites comparison
            </a>
            <button
              className="container__button container__button--platform"
              onClick={openModal}
            >
              Get 40 hrs free consultation!
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/aem.png"
              alt="Web Experiences"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Adobe-Experience-Manager-Banner"
        page="Adobe-Experience-Manager"
       
      />
    </section>
  );
}

export default AdobeExperienceManagerBanner;
