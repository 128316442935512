import React from "react";

function AdobeExperienceManagerSiteServices() {
  return (
    <section className="section section--services section--wave-bg --section-mlarge-tb-padding">
      <div className="container">
        <div className="container__row --flex-center --section-small-tb-padding">
          <div className="container__col-md-4 container__col-xs-12">
            <h2 className="container__heading">Our AEM Sites Services</h2>
            <div className="container__services">
              <div className="container__row">
                <div className="container__col-md-2 container__col-xs-2">
                  <img src="../../solutions/checkmark.png" alt="Hashout" />
                </div>
                <div className="container__col-md-10 container__col-xs-10">
                  <h5 className="container__sub-heading">Migration to Cloud</h5>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-2">
                  <img src="../../solutions/checkmark.png" alt="Hashout" />
                </div>
                <div className="container__col-md-10  container__col-xs-10">
                  <h5 className="container__sub-heading">
                    On-prem to newer versions and touch UI upgrades
                  </h5>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-2">
                  <img src="../../solutions/checkmark.png" alt="Hashout" />
                </div>
                <div className="container__col-md-10  container__col-xs-10">
                  <h5 className="container__sub-heading">
                    New AEM Implementation
                  </h5>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-2">
                  <img src="../../solutions/checkmark.png" alt="Hashout" />
                </div>
                <div className="container__col-md-10  container__col-xs-10">
                  <h5 className="container__sub-heading">
                    Ongoing development support
                  </h5>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-2">
                  <img src="../../solutions/checkmark.png" alt="Hashout" />
                </div>
                <div className="container__col-md-10  container__col-xs-10">
                  <h5 className="container__sub-heading">
                    AEM site Support and Maintenance
                  </h5>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-2">
                  <img src="../../solutions/checkmark.png" alt="Hashout" />
                </div>
                <div className="container__col-md-10  container__col-xs-10">
                  <h5 className="container__sub-heading">
                    Stabilization and Performance improvement
                  </h5>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-2">
                  <img src="../../solutions/checkmark.png" alt="Hashout" />
                </div>
                <div className="container__col-md-10  container__col-xs-10">
                  <h5 className="container__sub-heading">
                    Assessment of existing implementation
                  </h5>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-2">
                  <img src="../../solutions/checkmark.png" alt="Hashout" />
                </div>
                <div className="container__col-md-10  container__col-xs-10">
                  <h5 className="container__sub-heading">
                    3rd Party integrations
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="container__col-md-7 container__col-md-offset-1 container__col-xs-12 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/aem-sites-services.png"
              alt="Jamstack"
            />
          </div>
        </div>
      </div>
      {/* <!-- <img className="section__wave" src="../images/wave.svg" alt="Hashout" /> --> */}
    </section>
  );
}

export default AdobeExperienceManagerSiteServices;
