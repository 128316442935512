import React, { useState } from "react";

function AdobeExperienceManagerVideo() {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <>
      <section className="section section--video">
        <a
          href="#video-modal"
          onClick={() => {
            setShowVideo(true);
          }}
        >
          <div className="container-fluid">
            <img
              className="container-fluid__img"
              src="../../solutions/platforms/aem-sites-video.png"
              alt="aem-sites-video"
            />
            <div className="container-fluid__overlay"></div>
          </div>
          <div className="container">
            <div className="container__content">
              Meet Adobe Experience Manager Sites - Explore our CMS to create
              personalized webpages
            </div>
          </div>
        </a>
      </section>
      <div id="video-modal" className="modaloverlay">
        <div className="modaloverlay__modal">
          <a
            href="#close"
            className="modaloverlay__close"
            onClick={() => {
              setShowVideo(false);
            }}
          >
            &times;
          </a>
          <div className="modaloverlay__body">
            <div className="modaloverlay__embed-container">
              {showVideo && (
                <iframe
                  className="modaloverlay__iframe"
                  src="https://www.youtube.com/embed/ABj0yh-c_1U"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdobeExperienceManagerVideo;
