import React from "react";

function AdobeExperienceManagerTools() {
  return (
    <section className="section section--tools --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Tools and Accelerators</h2>
            <div className="container__row">
              <div className="container__col-md-7">
                <p className="container__content">
                  Our continously efforts to build common repeatable solutions
                  as tools ensures providing faster time to market and redueced
                  effor on our engagements. These tools are also available to be
                  distrubted stand-alone, reach out to us to learn more.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container__row --flex-center">
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card ">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/aem-algolia.png"
                alt="Adobe Experience Manager"
              />
              <a
                className=""
                href="/blog/Integrating-Algolia-Search-for-AEM-Sites"
                target="_blank"
              >
                <h5 className="card__title">AEM + Algolia Connector</h5>
              </a>
              <p className="card__description">
                Push connector to Algolia for Content and Assets on AEM.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/aem-salesforce.png"
                alt="AEM-Salesforce Connector"
              />
              <a
                href="../../../Hashout-Guide-to-connecting-AEM-and-CRM-for-self-service.pdf"
                target="_blank"
              >
                <h5 className="card__title">AEM-Salesforce Connector</h5>
              </a>
              <p className="card__description">
                Sync content from Salesforce Knowledge to AEM.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/custom-events-framework.png"
                alt="Custom Events Framework"
              />
              <a
                href="/blog/Understanding-Google-Custom-Search-Engine(CSE)-JSON-API"
                target="_blank"
              >
                <h5 className="card__title">Custom Events Framework</h5>
              </a>
              <p className="card__description">
                Advanced event collector to automatic capture interaction events
                to your own database and reporting tools.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/aem-salesforce-connector.png"
                alt="Broken Link Checker"
              />
              <a>
                <h5 className="card__title">AEM WordPress Migration Tool</h5>
              </a>
              <p className="card__description">
                Lift and Shift WordPress sites to AEM.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdobeExperienceManagerTools;
